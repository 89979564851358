<template>
	<div>
		<el-main>
			<!-- 第一块 -->
			<div class="introduction">
				<div class="introduction2">
					<div>
						<div style="width:175px; margin-left:12%;padding-top:35px">
							<h2 style="margin-top:0;margin-bottom:10px">公司简介</h2>
<!--							<h2 style="margin-top:10px">About Us</h2>-->
<!--              <p style="float: left">-->
<!--                宁夏创耀信科技有限公司是一家专注于物联网、软、硬件研发-->
<!--                提供信息技术服务的公司,主要致力于智慧牧场、智慧农业、智慧-->
<!--                工业、智慧城市等系统的研发及实施,已形成大数据物联网云平台-->
<!--                为基础的成熟系统。2018年被评为国家级、自治区级科技型中小企-->
<!--                业,在宁夏股权托管交易中心挂牌。-->
<!--                公司历经多年技术沉淀,以先进的理念以及技术研制出智慧牧-->
<!--                场系统,其中包含智慧牧场信息化管理平台、移动端智慧牧场系-->
<!--                统、TMR-DR饲喂管理系统、Heat-DR发情监测系统、CaIf-DR-->
<!--                犊牛饲喂管理系统、MRS-DR奶产量监测系统、以及MW-DR称重-->
<!--                系统、ENV-DR环境控制系统等,经市场检验,拥有较好的口碑,-->
<!--                用户使用情况优良。-->
<!--                公司与多家高校及科研机构合作,不断地改善升级系统,一贯-->
<!--                保持严谨的态度,保证系统的高效、稳定、可靠运行。公司到目前-->
<!--                为止共拥有25项知识产权,其中2项发明专利、9项实用新型专利和-->
<!--                14项软件著作权。-->
<!--                我们紧跟时代步伐,勇于挑战自身技术实力,为用户提供先进-->
<!--                理念,和卓越的技术支持,真正为客户着想,竭力为客户提高效益。-->
<!--              </p>-->
						</div>
						<div style="display:inline-block;width:32%;height:670px;margin-left:12%;overflow-y: hidden;line-height:39px" v-html="information">
							{{information}}
						</div>
						<div style="width:49%;height:685px;display:inline-block;">
							<img style="margin-left:60px;width:94%;" src="../../assets/js.png" alt="">
						</div>
					</div>
				</div>
			</div>
			<!-- 第二块 -->
			<div>
				<div style="width:100%;height:166px;background:#0f91ee;margin-top:5px">
					<div style="margin-left:22%;padding-top:1%;display: inline-block;">
						<h1 style="color:#ffffff;margin-top:0;font-size:36px;margin-bottom:10px">25</h1>
						<h3 style="color:#ffffff;font-size:15px; font-weight:15;">知识产权</h3>
					</div>
					<div style="margin-left:13%;padding-top:1;display: inline-block;">
						<h1 style="color:#ffffff;margin-top:0;font-size:36px;margin-bottom:10px">02</h1>
						<h3 style="color:#ffffff;font-size:15px; font-weight:15;">发明专利</h3>
					</div>
					<div style="margin-left:13%;padding-top:1;display: inline-block;">
						<h1 style="color:#ffffff;margin-top:0;font-size:36px;margin-bottom:10px">09</h1>
						<h3 style="color:#ffffff;font-size:15px; font-weight:15;">实用新型专利</h3>
					</div>
					<div style="margin-left:13%;padding-top:1%;display: inline-block;">
						<h1 style="color:#ffffff;margin-top:0;font-size:36px;margin-bottom:10px">14</h1>
						<h3 style="color:#ffffff;font-size:15px; font-weight:15;">软件著作产权</h3>
					</div>
				</div>
			</div>
			<div>
				<img class="obj" src="../../assets/obj.png" alt="">
			</div>
			<div class="zsy" style="width:100%;height:2403px;">
				<div class="zs1" style="width:62%;height:900px;margin:0 auto">
					<div>
						<h3 style="font-size:30px;color:#454545;margin-bottom: 10px">企业荣誉</h3>
						<p style="font-size:15px;color:#454545;">Enterprise honor</p>
					</div>
					<div class="zh2">
						<img src="../../assets/zs.png" alt="">
						<h3 style="font-size:23px;color:#454545;margin-bottom: -12px">企业证书</h3>
						<p style="font-size:15px;color:#454545;">qi ye zheng shu</p>
					</div>
					<div class="gp">
						<img class="img1" src="../../assets/gp2.png" alt="">
						<img class="img2" src="../../assets/gp1.png" alt="">
					</div>
					<div class="qyimg">
						<img src="../../assets/3.png" alt="">
						<img src="../../assets/5.png" alt="">
						<img src="../../assets/4.png" alt="">
						<img src="../../assets/6.png" alt="">
					</div>
					<div class="qyimg1">
						<img src="../../assets/3.png" alt="">
						<img src="../../assets/5.png" alt="">
						<img src="../../assets/4.png" alt="">
						<img src="../../assets/6.png" alt="">
					</div>
					<div class="qyimg2">
						<img src="../../assets/3.png" alt="">
						<img src="../../assets/5.png" alt="">
						<img src="../../assets/4.png" alt="">
						<img src="../../assets/6.png" alt="">
					</div>
					<div class="qyimg3">
						<img src="../../assets/3.png" alt="">
						<img src="../../assets/5.png" alt="">
						<img src="../../assets/4.png" alt="">
						<img src="../../assets/6.png" alt="">
					</div>
					<div class="qyimg4">
						<img src="../../assets/3.png" alt="">
						<img src="../../assets/5.png" alt="">
						<img src="../../assets/4.png" alt="">
						<img src="../../assets/6.png" alt="">
					</div>
				</div>
			</div>
		</el-main>
	</div>
</template>

<script>
export default {
	name: "AboutUs",
	data() {
		return {
			information: '',
			images: [],
		}
	},
	mounted() {
		this.profile();
	},
	methods:{
		//  公司简介请求方法
		async profile() {
			var that =this;
			const {data: res}=await this.$http.get('/gw/news/getWzContentList?type=4&limit=1',{
				params: this.res,
			})
			that.information=res.data[0].content;
		},
	}
}
</script>

<style lang="scss" scoped>
.obj {
	margin: 0 auto;
	width: 61%;
	margin-left: 19%;
}
.el-header{
	height: 146px !important;
	padding: 0 !important;

}
.el-main {
	width: 100%;
	/* height: 8000px !important; */
	padding: 0 !important;
	/* background-color: darkblue; */
	overflow: none;
}
.containerd {
	width: 100% !important;
	height: 4285px  !important;
	/* background-color: aqua; */
	z-index: 999;
	margin: 0;
}
.headerx {
	position: relative;
	width: 80%;
	height: 80px;
	/* background-color: brown; */
	margin: 0 auto;
}
.locki {
	width: 398px;
	display: inline-block;
	margin-left: 20px;
}
.logo {
	margin-top: 13px;
}
.zx {
	margin-left: 30px;
	margin-bottom: 15px;
}
.text1 {
	position: absolute;
	margin-top: 13px;
	margin-left: 30px;
	width: 38%;
	height: 68px;
	display: inline-block;
	color: #595a63;
}
.contact {
	position: absolute;
	display: inline-block;
	height: 80px;
	width: 10%;
	right: 12%;
	margin-top: -0.2%;
	margin-bottom: 0;
	color: #0f91ee;
}
.ul {
	width: 160px;
}
.p1 {
	display: inline-block;
	padding-left: 15px;
}
.p2 {
	display: inline-block;
	padding-left: 15px;
}
.li1 {
	height: 30px;
}
.ewm {
	position: absolute;
	display: inline-block;
	right: 6%;
	margin-top: 13px;
	left: 90%;
}
.navigation {
	width: 100% !important;
	height: 50px;
	/* background-color: #0f91ee; */
	z-index: 999;
}
.menu {
	width:100% ;
	margin: 0 auto;
}
.menu>.el-button {
	border-radius: 0;
	margin: 0;
	border: 0;
	border-left: 1px solid #bdbdbd;
	background-color: #0f91ee;
	color: #fff;
}
.block {
	height: 680px;
	width: 100%;
}
.el-carousel{
	overflow-x:hidden;
	overflow-y: hidden;
}

.image{
	height: 550px;
}

.el-carousel__item:nth-child(2n+1) {
	background-color: #d3dce6;
}
.el-carousel__container{
	position: relative;
	height: 650px !important;
	z-index: 999;
}
.introduction {
	height: 798px;
	width: 100%;
	/* background-color: blue; */
}
.introduction1 {
	width: 1200px;
	height: 285px;
	margin: 0 auto;
	/* background-color: darkgoldenrod; */
}
.introduction1>div {
	display: inline-block;
	width: 300px;
	/* margin-top: 50PX;
	margin-left: 150px; */
}
.introduction1>div>img{
	padding-top: 50px;
	padding-left: 95px;
}
.introduction1>div>h3{
	color: #717171;
	text-align: center;
	margin-top: 8px;

}
.introduction1>div>p{
	color: #717171;
	text-align: center;
	width: 250px;
	margin-left: 20px;
}
.introduction2 {
	width: 100%;
	height: 75%;
	/* background-color: darkgray; */
	/* margin-left: 354px; */
}
.xm>h2{
	margin-left: 19%;
}
.xmyy {
	position: relative;
}
.zxx {
	position: absolute;
	top: 0;
	right: 74px;
	color: #fff;
	font-size: 25px;
	line-height: 50px;
}
.app {
	position: absolute;
	top: 106px;
	left: 635px;
}
.pc {
	position: absolute;
	top: 445px;
	left: 635px;
}
.apptext {
	position: absolute;
	top: 104px;
	left: 470px;
	font-size: 30px;
}
.pctext{
	position: absolute;
	top: 470px;
	left: 470px;
	font-size: 30px;
}
.xm {
	position: relative;
}
/* 下拉框样式 */
.el-dropdown {
	vertical-align: top;
}
.el-dropdown + .el-dropdown {
	margin-left: 15px;
	width: 500px !important;
}
.el-icon-arrow-down {
	font-size: 15px;
}
.proimg {
	margin-left: 19%;
}

.ljzx{
	position: absolute;
	top: 9px;
	right: 28px;
}
.lxtext {
	position: absolute;
	top: 44%;
	left: 55%;
	color: #fdfdfd;
	text-align: center;
	font-size: 17px;
}
.cooperation {
	position: absolute;
	width: 64%;
	height: 324px;
	/* background-color: bisque; */
	left: 19%;
}
.cooperation>div {
	display: inline-block;
	margin-right: 1%;
	margin-top: 1%;
	margin-bottom: 1%;
	width: 15%;
	height: 50px;
	border: 1px solid #717171;
	text-align: center;
	overflow: hidden;
	font-size: 13px;
}
.cooperation>div>span {
	width: 188px;
	height: 50px;
	text-align: center;
	margin-top: 50px;
	line-height: 44px;
}
.footer {
	position: relative;
}
.footerkuai {
	position: absolute;
	top: 0;
	left: 19%;
}
.footerkuai>div {
	display: inline-block;
	/* border: 1px solid #fff; */
	color: #4b4f54;
}
.td {
	position: relative;
}
.rx1 {
	position: absolute;
	top: 87px;
	left: 0px;
}
.rx2 {
	position: absolute;
	top: 87px;
	left: 80px;
}
.rx3 {
	position: absolute;
	top: 87px;
	left: 160px;

}
.rx4 {
	position: absolute;
	top: 165px;
	left: 0px;

}
.dz {
	position: absolute;
	top: 50px;
}
.email1 {
	position: absolute;
	top: 92px;
}
.phone {
	position: absolute;
	top: 134px;
}
.sj1 {
	position: absolute;
	top: 195px;
}
.sj1>p {
	display: inline-block;
}
.dhbj {
	position: relative;
	position: absolute;
	top: 330px;
	left: 19%;
}
.dhbj>p {
	position: absolute;
	top: 5px;
	color: #494d52;
}
.crumbs {
	position: absolute;
	color: #494d52;
	left: 78%;
	top: 20px;
}
.zsy {
	position: relative;
}
.zsy1 {
	position: absolute;
	width:251px;
	height:150px;
}
.zh2 {
	position: absolute;
	width:251px;
	height:150px;
	left: 45%;
	text-align: center;
}
.zh2.h3 {
	margin-top: 0;
}
.gp {
	position: relative;
	margin-top: 23%;
	width: 62%;
	height: 316px;
}
.img1 {
	position: absolute;
	left: 18%;
	width: 59%;
}
.img2 {
	position: absolute;
	left: 90%;
	width: 59%;
}
.qyimg {
	width: 100%;
	height: 36%;
	/* background: #bdbdbd; */
	margin-top: 8%;

}
.qyimg1 {
	width: 100%;
	height: 36%;
	/* background: #bdbdbd; */
	margin-top: 0%;

}
.qyimg2 {
	width: 100%;
	height: 36%;
	/* background: #bdbdbd; */
	margin-top: 0%;

}
.qyimg3{
	width: 100%;
	height: 36%;
	/* background: #bdbdbd; */
	margin-top: 0%;
}
.qyimg4{
	width: 100%;
	height: 36%;
	/* background: #bdbdbd; */
	margin-top: 0%;
}
.qyimg>img {
	width: 21%;
	/* width: ; */
	margin-left: 3%;
}
.qyimg1>img {
	width: 21%;
	/* width: ; */
	margin-left: 3%;
}
.qyimg2>img {
	width: 21%;
	/* width: ; */
	margin-left: 3%;
}
.qyimg3>img {
	width: 21%;
	/* width: ; */
	margin-left: 3%;
}
.qyimg4>img {
	width: 21%;
	/* width: ; */
	margin-left: 3%;
}
.crumbs {
	width: 32%;
	margin-left: -28%;
}

</style>
